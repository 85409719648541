import React from 'react'
import { Heading } from 'theme-ui'
import Divider from '@components/Divider'
import './TableStyle.css'

const product1TEST = {
  name: 'Product 1',
  link: 'https://www.gatsbyjs.com/docs/creating-and-modifying-pages/',
  categories: [
    {
      name: 'Account Fees',
      features: [
        {
          name: 'Margin Percent',
          value: '10%',
        },
        {
          name: 'IRA Fee',
          value: '$100',
        },
      ],
    },
    {
      name: 'Options',
      features: [
        {
          name: 'Trading',
          value: '✔️ on margin',
        },
        {
          name: 'Condor',
          value: '❌',
        },
      ],
    },
  ],
}

const product2TEST = {
  name: 'Product 2',
  link: 'https://flexiblog-agency.netlify.app/',
  categories: [
    
    {
      name: 'Options',
      features: [
        {
          name: 'Trading',
          value: '✔️',
        },
        {
          name: 'Condor',
          value: '❌',
        },
      ],
    },
    {
      name: 'Account Fees',
      features: [
        // {
        //   name: 'Margin Percent',
        //   value: '20%',
        // },
        {
          name: 'IRA Fee',
          value: '$86',
        },
      ],
    },
  ],
}

export default ({ pageContext: { product1, product2, images } = {} }) => {

  return (
    <>
      <Divider space={1} />
      <div className='table'>
        <div className='table-cell platform'></div>
        <div className='table-cell plattform'>
          <h3>{product1.name}</h3>
          <a
            target='_blank'
            rel='noopener sponsored'
            href={product1.link}
            className='btn'
          >
            Visit
          </a>
        </div>
        <div className='table-cell plattform'>
          <h3>{product2.name}</h3>
          <a
            target='_blank'
            rel='noopener sponsored'
            href={product2.link}
            className='btn'
          >
            Visit
          </a>
        </div>
      </div>
      {product1.categories &&
        product1.categories.map((category, i) => {
          const prod2Category = product2.categories.find(
            (c) => c.name == category.name
          )
          return (
            <div key={i}>
              <Divider space={3} />
              <Heading variant='h2'>{category.name}</Heading>
              <Divider space={3} />
              <div>
                {category.features.map((feature, j) => {
                  const featureName = feature.name
                  const prod1Feature = feature.value
                  let prod2Feature =
                    prod2Category &&
                    prod2Category.features.find((f) => f.name == featureName)
                  if (prod2Feature) prod2Feature = prod2Feature.value
                  
                  return (
                    <div className='table' key={j}>
                      <div className='table-cell cell-feature'>
                        {featureName}
                      </div>
                      <div className='table-cell'>
                        {prod1Feature ? prod1Feature : 'N/A'}
                      </div>
                      <div className='table-cell'>
                        {prod2Feature ? prod2Feature : 'N/A'}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}

      <Divider space={3} />
    </>
  )
}
