import React from 'react'
import CompareIntro from '../components/Compare/Compare.Intro'
import CompareTable from '../components/Compare/Compare.Table'
import { Stack, Main } from '@layout'
import { Card as CardComponent } from 'theme-ui'
import { PostImage, PostTagsShare } from '@widgets/Post'

import Card from '@components/Card'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const Compare = ({ location, ...props }) => {
  const {
    pageContext: { services = {}, product1, product2, slug, images } = {},
  } = props
  const post = {
    title: `${product1.name} vs. ${product2.name}`,
    slug,
    thumbnail:{
      dual1: images.product1 && images.product1.childImageSharp.fixed,
      dual2: images.product2 && images.product2.childImageSharp.fixed
    },
    category: {
      color: '#c6f6d5',
      name: 'Compare',
      slug: '/category/compare/',
      
    },
  }
  return (
    <>
      <Seo title={post.title} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <Main>
          <Card
            {...post}
            variant='horizontal-hero'
            omitExcerpt
            omitMedia
            omitFooter
          />
        </Main>
      </Stack>
      <Divider space={3} />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <CardComponent variant='paper-lg'>
            <PostImage {...post} dual />
            <CompareIntro {...props} />
            <CompareTable {...props} />
            <PostTagsShare {...post} location={location} />
          </CardComponent>
        </Main>
      </Stack>
    </>
  )
}

export default Compare
