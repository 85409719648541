import { graphql } from 'gatsby'
import ComparePage from '../containers/Compare'

export default ComparePage

// export const pageQuery = graphql`
//   query ComparePageQuery(
//     $image1_id: String!
//     $image2_id: String!
//   ) {
//     imageSharp(id: {$image1_id}) {
//         vertical: fixed(width: 380, height: 290, cropFocus: CENTER) {
//           src
//           srcSet
//           srcWebp
//           srcSetWebp
//           width
//           height
//         }
//         hero: fixed(width: 1600, height: 650, cropFocus: CENTER) {
//           src
//           srcSet
//           srcWebp
//           srcSetWebp
//           width
//           height
//         }
//       }
//     }
//   }
// `
