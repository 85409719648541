import React from 'react'
import { Heading } from 'theme-ui'
import Divider from '@components/Divider'

/**
 * Shadow me to add your own content
 */

const styles = {
  running: {
    color: `omegaDark`,
    fontWeight: `normal`
  }
}

export default ({pageContext: { product1, product2 } = {}}) => {
  
  return (
    <>
      <Divider space={1} />
      <Heading variant='h3' sx={styles.running}>
        Is {product1.name} superior to {product2.name}? Let's see...
      </Heading>
      <Divider space={3} />
    </>
  )
}
